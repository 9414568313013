<template>
    
    <div v-if="show" class="livePage">
        <div class="header2">
            <div class="roundButton">
                <router-link :to="{name:'Show', params:{slug:leftClick}}">
                    <TheIcon icon="left" :outline="false" :background="false"/>
                </router-link>
            </div>
            
            <div class="headerContent">
                <div class="timeSlot" v-html="show.custom_time_slots"></div>
                <div class="showTitle">{{ show.title }}</div>
            </div>
            
            <div class="roundButton">
                <router-link :to="{name:'Show', params:{slug:rightClick}}">
                    <TheIcon icon="right" :outline="false" :background="false"/>
                </router-link>
            </div>
        </div>



        <!-- gallery ----------------------------------------------------------------------------->
        <!-- l'immagine strecciabile sarebbe un attributo CSS ma si passa pure di qua-->
        <div class="stationPicture" :style="{'background-image': 'url('+show.background+')'}">
            <div class="imageShadowsContainer">
                <div class="topShadow">
                </div>
                <div class="middleSpacer"> &nbsp; <br> &nbsp; </div>
                <div class="bottomShadow">
                    <!--array link della radio-->
                    <div v-for="item in show.external_links" :key="item.id" class="bottomShadowIcons">
                        <a :href="item.target_url" target="_blank" rel="noopener noreferrer">
                            <!-- <img class="bottomShadowIcons" :src="require(`@/assets/icons/social/buridda.png`)"> -->
                            <TheIcon :icon="item.social" :background="false"/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <!-- subito dopo in onda ----------------------------------------------------------------->
        <div class="bottomSlot">
            <p class="showLongDescription">{{show.long_description}}</p>
        </div>
        <div class="listContainer" v-if="show.last_podcast">
            <div class="simpleContainer">
                <div class="roundButton">
                    <!--<a v-on:click="play(show.podcasts[0])" href="#">-->
                        <a v-on:click="play(show)" href="#">
                        <TheIcon icon="play" :outline="false" :background="false"/>
                    </a>
                </div>
                <div class="podcastData">
                    <div class="podcastTitle">{{ show.last_podcast.title }}</div>
                    <div class="timeSlot">{{ new Date(show.last_podcast.publicated_at).toLocaleDateString() }}</div>
                    <div class="showDescription">{{ show.last_podcast.description }}</div>
                </div>
                <div class="roundButton">
                    <router-link :to="{name: 'Podcast', params:{slug:this.slug, show:this.show}}">
                        <TheIcon icon="up" :outline="false" :background="false"/>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
    <div v-else class="loadingContainer"><TheLoading/></div>
    
</template>

<script>
// @ is an alias to /src
//import axios from "axios";
import TheIcon from '@/components/TheIcon'
import TheLoading from '@/components/TheLoading'
import axios from "axios";

export default {
  name: 'Show',
  components: {
    TheIcon,
    TheLoading
  },
  data(){
    return{
      error: false,
      //formatDDMMYY: function(val){
      //  let data=new Date(val)
      //  let d = data.getDay()
      //},
      //show: null,
      //slugs: null,
    }
  },
  props:{
    slug:{
      type: String,
      required: true
    }
  },
  mounted(){
    if(!this.$podcasts[this.slug]){
      axios.defaults.headers['Pragma'] = 'no-cache';
      axios.defaults.headers['Cache-Control'] = 'no-cache';
      axios
        .get("/api/stations/radiogramma/podcasts/?show__slug="+this.slug)
        .then(response => {
          if(response.data){
            this.$podcasts[this.slug]=response.data.slice()
          }else{
            this.$router.push({name:'NotFound', params:{error: 'Wrong slug'}})
          }
        })
        .catch(err => {
          this.$router.push({name:'NotFound', params:{error: err.message}})
        })
    }
  },
  watch:{
    slugs(value){
      if(value==0){
        this.$router.push({name:'NotFound', params:{error: 'Empty response'}})
      }
      //this.slugs=response.data.shows.map(i => i.slug)
      //this.show=response.data.shows.find( show => show.slug === this.slug)
    }
  },
  computed:{
    show(){
      if(this.$shows){
          if(this.$shows.find( show => show.slug === this.slug)){
             return this.$shows.find( show => show.slug === this.slug)
          
          }else{
             return 0
          }
      }
      else{
        return null
      }
    },
    slugs(){
      if(this.$shows){
          if(this.$shows.find( show => show.slug === this.slug)){
             return this.$shows.map(i => i.slug)
          }else{
             return 0
          }
      }
      else{
        return null
      }
    },
    leftClick(){
        let ids=this.$shows.map(show => show.id)
        if(ids.indexOf(this.show.id)-1>=0){
            return this.slugs[ids.indexOf(this.show.id)-1]
        }else{
            return this.slugs[this.slugs.length-1]
        }
    },
    rightClick(){
        let ids=this.$shows.map(show => show.id)
        if(ids.indexOf(this.show.id)+1<this.slugs.length){
            return this.slugs[ids.indexOf(this.show.id)+1]
        }else{
            return this.slugs[0]
        }
    },
    
  },
  methods:{
    play(e){
        this.$player = {
            playerMeta:{
                isPodcast: true,
                showTitle: e.title,
                showSlug:  e.slug,
                showTimeSlot:  e.custom_time_slots,
                showIcon: e.icon,
                podcastTitle:  e.last_podcast.title,
                podcastDesc:   e.last_podcast.description,
                podcastUpload: new Date(e.last_podcast.upload).toLocaleDateString(),
                podcastId: 0,
            },
            playerUrl: e.last_podcast.url
            //file: e.url            
        }
    }
  }
}
</script>
